import { Button, Modal, message, Input } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { useResetPassword } from '../networks';
import { Order } from '../types';

interface OrderPasswordModalProps {
  visible: boolean;
  toggleVisible: () => void;
  data?: Order;
  onRefetch: () => void;
}

const OrderPasswordModal: React.FC<OrderPasswordModalProps> = ({
  visible,
  toggleVisible,
  data = null,
  onRefetch,
}) => {
  const [password, setPassword] = useState('');

  const { mutateAsync, isLoading } = useResetPassword();

  const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const body = {
        order_id: data?.id as number,
        password,
      };

      await mutateAsync(body);
      message.success('berhasil reset passsword');
      onRefetch();
      toggleVisible();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.msg || 'terjadi kesalahan, silahkan coba lagi';
      message.error(errorMessage);
    }
  };

  return (
    <Modal
      onCancel={toggleVisible}
      visible={visible}
      title='Set Password'
      footer={[
        <Button key='back' onClick={toggleVisible}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={isLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <div>
        <p>
          Set password untuk <b>{data?.user?.email}</b>
        </p>
        <Input.Password
          placeholder='Password'
          value={password}
          onChange={handleChangeAmount}
          style={{ width: 200 }}
        />
      </div>
    </Modal>
  );
};

export default OrderPasswordModal;
