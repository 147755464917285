import { api } from 'utils';
import { OrderResponse, PaymentResponse } from '../types';
import endpoints from './endpoint';

interface UpgradePackageBody {
  oderId: string;
  package: number;
}

interface UpdatePaymentStatusBody {
  order_id: string;
  payment_status: boolean;
}

interface AddManualPaymentBody {
  oderId: string;
  amount: number;
  proofUrl: string;
}

interface ResetPasswordBody {
  order_id: number;
  password: string;
}
interface RegisterUserBody {
  fullname: string;
  email: string;
  password: string;
  package_id?: number;
  sub_domain?: string;
  theme_id?: number;
}
/**
 *  get order list
 */
export const getOrderList = (
  search: string,
  page: number,
  pageSize: number
): OrderResponse => {
  return api.get(endpoints.orderList(search, page, pageSize));
};

/**
 * upgrade package
 */
export const postUpgradePackage = (body: UpgradePackageBody) => {
  return api.post(endpoints.upgradePackage(body.oderId), {
    package: body.package,
  });
};

/**
 * update payment status
 */
export const postUpdatePaymentStatus = (body: UpdatePaymentStatusBody) => {
  return api.post(endpoints.updatePaymentStatus, body);
};

/**
 * add manual payment
 */
export const postAddManualPayement = (body: AddManualPaymentBody) => {
  return api.post(endpoints.addManualPayment(body.oderId), {
    proof_url: body.proofUrl,
    amount: body.amount,
  });
};

/**
 * get login token
 */
export const getLoginToken = (orderId: string) => {
  return api.post(endpoints.getLoginToken(orderId));
};

/**
 * get login token
 */
export const getPaymentList = (orderId: string): PaymentResponse => {
  return api.get(endpoints.getPaymentList(orderId));
};

/**
 * reset password
 */
export const postResetPassword = (body: ResetPasswordBody) => {
  return api.post(endpoints.resetPassword, body);
};

/**
 * register user
 */
export const postRegisterUser = (body: RegisterUserBody) => {
  return api.post(endpoints.registerUser, body);
};
