import { api } from 'utils';
import { BankListResponse } from '../types';
import endpoints from './endpoint';

/**
 *  get bank list
 */
export const getBankList = (): BankListResponse => {
  return api.get(endpoints.bankList);
};

/**
 * add bank
 */
export const postAddBank = (body: FormData) => {
  return api.post(endpoints.addBank, body);
};

/**
 * update bank
 */
export const postUpdateBank = (body: FormData) => {
  return api.post(endpoints.updateBank, body);
};

/**
 * remove bank list
 */
export const postRemoveBank = (id: string) => {
  return api.post(endpoints.deleteBank, { id });
};
