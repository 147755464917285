import { api } from 'utils';
import endpoints from './endpoint';

export interface BodyLogin {
  email: string;
  password: string;
  is_remembered: boolean;
}

// login
export const postLogin = (body: BodyLogin) => {
  return api.post(endpoints.login, body);
};
