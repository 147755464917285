import { Button, Select, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useUpdatePaymentStatus } from '../networks';
import { Order } from '../types';

const { Option } = Select;

interface OrderPaymentModalProps {
  visible: boolean;
  toggleVisible: () => void;
  data?: Order;
  onRefetch: () => void;
}

const OrderPaymentStatusModal: React.FC<OrderPaymentModalProps> = ({
  visible,
  toggleVisible,
  data = null,
  onRefetch,
}) => {
  const [paymentStatus, setPaymentStatus] = useState(0);

  const {
    mutateAsync: updatePaymentStatus,
    isLoading: updatePaymentStatusLoading,
  } = useUpdatePaymentStatus();

  const handleChange = (value: number) => {
    setPaymentStatus(value);
  };

  useEffect(() => {
    if (visible) {
      setPaymentStatus(data?.payment_status === 'lunas' ? 1 : 0);
    }
  }, [visible, data]);

  const handleSubmit = async () => {
    try {
      const body = {
        order_id: `${data?.id}`,
        payment_status: Boolean(paymentStatus),
      };

      await updatePaymentStatus(body);
      message.success('berhasil update payment status');

      onRefetch();
      toggleVisible();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.msg || 'terjadi kesalahan, silahkan coba lagi';
      message.error(errorMessage);
    }
  };

  return (
    <Modal
      onCancel={toggleVisible}
      visible={visible}
      title='Order Payment'
      footer={[
        <Button key='back' onClick={toggleVisible}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={updatePaymentStatusLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Select
        style={{ width: 200 }}
        onChange={handleChange}
        value={paymentStatus}
      >
        <Option value={0}>Belum Lunas</Option>
        <Option value={1}>Lunas</Option>
      </Select>
    </Modal>
  );
};

export default OrderPaymentStatusModal;
