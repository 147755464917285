import { useMutation, useQuery } from 'react-query';
import { getProfile, postUploadCloudinary } from './services';

/**
 * Fetching source list
 */
export const useFetchProfile = (enable = true) => {
  return useQuery(['Profile'], () => getProfile(), {
    enabled: enable,
  });
};

/**
 * post upload image to cloudinary
 */
export const useUploadCloudinary = () => {
  return useMutation(postUploadCloudinary);
};
