import { useMutation, useQuery } from 'react-query';
import {
  getBankList,
  postAddBank,
  postRemoveBank,
  postUpdateBank,
} from './services';

/**
 * Fetching order list
 */
export const useFetchBankList = (enabled = true) => {
  return useQuery(['Bank List'], () => getBankList(), {
    enabled,
  });
};

export const useAddBank = () => useMutation(postAddBank);

export const useUpadateBank = () => useMutation(postUpdateBank);

export const useRemoveBank = () => useMutation(postRemoveBank);
