import { Button, Select, Modal, message, Upload, Input } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { Bank } from '../types';
import { useAddBank, useUpadateBank } from '../networks';

const { Option } = Select;

interface OrderPaymentModalProps {
  visible: boolean;
  onClose: () => void;
  data?: Bank;
  onRefetch: () => void;
}

const OrderPaymentStatusModal: React.FC<OrderPaymentModalProps> = ({
  visible,
  onClose,
  data,
  onRefetch,
}) => {
  const [bankType, setBankType] = useState(0);
  const [bankName, setBankName] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const {
    mutateAsync: updateBank,
    isLoading: updateBankLoading,
  } = useUpadateBank();

  const { mutateAsync: addBank, isLoading: addBankLoading } = useAddBank();

  const handleChange = (value: number) => {
    setBankType(value);
  };

  useEffect(() => {
    if (visible) {
      if (data) {
        setBankName(data.name);
        setBankType(data.type === 'bank' ? 0 : 1);
        setBankType(data?.type === 'bank' ? 0 : 1);
        setImageUrl(data.icon_url);
      }
    } else {
      setBankName(undefined);
      setBankType(0);
      setFileList([]);
      setImageUrl(null);
    }
  }, [visible, data]);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('name', bankName as string);
      formData.append('type', bankType ? 'ewallet' : 'bank');

      if (fileList.length) {
        const file = fileList[0].originFileObj;
        formData.append('file', file as File);
      }

      if (data?.id) {
        formData.append('id', `${data.id}`);

        await updateBank(formData);
      } else {
        await addBank(formData);
      }

      message.success('berhasil update payment status');

      onRefetch();
      onClose();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.msg || 'terjadi kesalahan, silahkan coba lagi';
      message.error(errorMessage);
    }
  };

  const handleFileChange = (info: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result as any);
    };
    reader.readAsDataURL(info.file);

    setFileList(info.fileList); // Update fileList to include the selected file
  };

  const handleBeforeUpload = (file: File) => {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isImage) {
      message.error('You can only upload JPG/PNG file!');
    }
    return false;
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setBankName(event.target.value);
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title={`${data ? 'Update' : 'Add'} Bank`}
      footer={[
        <Button key='back' onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={updateBankLoading || addBankLoading}
          disabled={updateBankLoading || addBankLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 10 }}>
        <Input
          placeholder='input bank name'
          onChange={handleChangeName}
          value={bankName}
        />
      </div>

      <div style={{ marginBottom: 10 }}>
        <Select
          style={{ width: '100%' }}
          onChange={handleChange}
          value={bankType}
          placeholder='select bank type'
        >
          <Option value={0}>bank</Option>
          <Option value={1}>e-wallet</Option>
        </Select>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Upload
          accept='image/png, image/jpeg'
          onChange={handleFileChange}
          beforeUpload={handleBeforeUpload}
          fileList={fileList}
          maxCount={1}
          showUploadList={false}
          style={{ width: '100%' }}
        >
          <Button icon={<UploadOutlined />}>Click to Select Image</Button>
        </Upload>
      </div>
      {imageUrl && <img src={imageUrl} alt='bank-icon' width={200} />}
    </Modal>
  );
};

export default OrderPaymentStatusModal;
