const endpoints = {
  orderList: (search: string, page: number, pageSize: number) =>
    `/orders/list?search=${search ?? ''}&page=${page}&limit=${pageSize}`,

  upgradePackage: (orderId: string) =>
    `/admin/order/${orderId}/package/upgrade`,

  updatePaymentStatus: `/admin/status-bayar`,

  addManualPayment: (orderId: string) =>
    `/admin/order/${orderId}/payment/manual/add`,

  getPaymentList: (orderId: string) => `/admin/order/${orderId}/payment/list`,

  getLoginToken: (orderId: string) => `/admin/order/${orderId}/login`,

  resetPassword: `/admin/reset-password`,

  registerUser: `/admin/user-register`,
};

export default endpoints;
