import React, { ChangeEvent, useState } from 'react';
import debounce from 'lodash.debounce';
import { Button, Input, Pagination, Space, Table } from 'antd';
import useToggle from 'hooks/useToggle';
import { useFetchOrderList } from '../networks';
import { Order } from '../types';

import {
  OrderPackageModal,
  OrderPaymentListModal,
  OrderPaymentStatusModal,
  OrderPasswordModal,
  RegisterUserModal,
} from '../modals';
import styles from './OrderPage.module.css';

const { REACT_APP_CLIENT_URL } = process.env;

if (!REACT_APP_CLIENT_URL) {
  throw new Error('make sure ENV has been set');
}
const textColor = {
  lunas: 'green',
  'belum dibayar': 'red',
};

const OrderTable: React.FC = () => {
  const [modalData, setModalData] = useState<Order>();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [packageVisible, togglePackageVisible] = useToggle();

  const [paymentStatusVisible, togglePaymentStatusVisible] = useToggle();

  const [paymentListVisible, togglePaymentListVisible] = useToggle();

  const [passwordModalVisible, togglePasswordModalVisible] = useToggle();
  const [registerModalVisible, toggleRegisterModalVisible] = useToggle();

  const { data, isFetching: orderListLoading, refetch } = useFetchOrderList(
    search,
    page,
    pageSize
  );

  const orderListData = data?.data?.data;
  const orderList = (orderListData?.data || []).map((item, index) => ({
    no: index + 1,
    ...item,
  }));

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const debouncedHandleChangeSearch = debounce(handleChangeSearch, 400);

  // const handleClickPaymentList = (record: Order) => {
  //   setModalData(record);
  //   togglePaymentListVisible();
  // };

  const handleClickPaymentStatus = (record: Order) => {
    setModalData(record);
    togglePaymentStatusVisible();
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChangePagination = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  // const handleClickPackage = (record: Order) => {
  //   setModalData(record);
  //   togglePackageVisible();
  // };

  const handleClickManualPayment = (record: Order) => {
    setModalData(record);
    togglePasswordModalVisible();
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (text: string) => <div>{text ?? '-'}</div>,
      width: 60,
    },

    {
      title: 'Sub Domain',
      dataIndex: 'sub_domain',
      key: 'sub_domain',
      render: (text: string) => <div>{text || '-'}</div>,
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      key: 'email',
      render: (text: string) => <div>{text || '-'}</div>,
      width: 300,
    },
    {
      title: 'Name',
      dataIndex: ['user', 'fullname'],
      key: 'fullname',
      render: (text: string) => <div>{text || '-'}</div>,
      width: 300,
    },
    {
      title: 'Phone',
      dataIndex: ['user', 'phone'],
      key: 'phone',
      render: (text: string) => <div>{text || '-'}</div>,
      width: 200,
    },

    {
      title: 'Package',
      dataIndex: ['package', 'name'],
      key: 'package',
      render: (text: string) => <div>{text || '-'}</div>,
      width: 100,
    },
    {
      title: 'Source',
      dataIndex: ['user', 'source'],
      key: 'source',
      render: (text: string) => <div>{text || '-'}</div>,
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <div>{text ?? '-'}</div>,
    },
    {
      title: 'Payment',
      dataIndex: 'payment_status',
      key: 'payment_status',

      render: (text: string, record: Order) => (
        <div
          className='cursor-pointer'
          onClick={() => handleClickPaymentStatus(record)}
        >
          <b
            style={{
              color: textColor[text as keyof typeof textColor] || 'black',
            }}
          >
            {text || '-'}
          </b>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: Order) => (
        <Space size='middle'>
          {/* <Button onClick={() => handleClickPaymentList(record)}>
            list Payment
          </Button>
          <Button onClick={() => handleClickManualPayment(record)}>
            Add Payment
          </Button> */}
          <Button onClick={() => handleClickManualPayment(record)}>
            Set Password
          </Button>
          <Button
            onClick={() => {
              window.open(
                `${REACT_APP_CLIENT_URL}/login?email=admin@nikaah.id&sub_domain=${record.sub_domain}`
              );
            }}
          >
            Login Client
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ overflow: 'auto' }}>
      <div className={styles['order-header']}>
        <h1>Order</h1>
        <div className={styles['right']}>
          <Input
            placeholder='Search'
            style={{ width: 240 }}
            onChange={debouncedHandleChangeSearch}
            allowClear
          />
          <Button onClick={toggleRegisterModalVisible}>Add User</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={orderList}
        loading={orderListLoading}
        rowKey={(record) => record.id}
        pagination={false}
      />
      <div className={styles['pagination-wrapper']}>
        <Pagination
          pageSize={pageSize}
          current={page}
          onChange={handleChangePagination}
          total={orderListData?.total}
        />
      </div>

      <OrderPaymentStatusModal
        visible={paymentStatusVisible}
        toggleVisible={togglePaymentStatusVisible}
        data={modalData}
        onRefetch={refetch}
      />

      <OrderPaymentListModal
        visible={paymentListVisible}
        toggleVisible={togglePaymentListVisible}
        data={modalData}
      />

      <OrderPasswordModal
        visible={passwordModalVisible}
        toggleVisible={togglePasswordModalVisible}
        data={modalData}
        onRefetch={refetch}
      />

      <RegisterUserModal
        visible={registerModalVisible}
        toggleVisible={toggleRegisterModalVisible}
        onRefetch={refetch}
      />

      <OrderPackageModal
        visible={packageVisible}
        toggleVisible={togglePackageVisible}
        data={modalData}
      />
    </div>
  );
};

export default OrderTable;
