import React from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/common/logo.svg';

import styles from './LoginPage.module.css';
import { useLogin } from '../networks';

type LoginForm = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = new URLSearchParams(location?.search).get('redirect');
  const locationState = location.state as { from: string };

  const { handleSubmit, control } = useForm<LoginForm>();

  const { isLoading, mutateAsync: login } = useLogin();

  const handleSubmitLogin: SubmitHandler<LoginForm> = async (data) => {
    const param = {
      email: data.email,
      password: data.password,
      is_remembered: true,
    };

    try {
      const res = await login(param);
      const resData = res?.data?.data;
      localStorage.setItem('nikaah_admin', JSON.stringify(resData));

      if (locationState?.from) {
        return navigate(locationState?.from, { replace: true });
      }

      if (redirect) {
        return navigate(redirect, { replace: true });
      }

      navigate('/', { replace: true });
    } catch (err) {
      const errorMessage =
        err?.response?.data?.msg || 'terjadi kesalahan, silahkan coba lagi';
      message.error(errorMessage);
    }
  };

  return (
    <div className={styles['login']}>
      <Card className={styles['card']}>
        <form
          onSubmit={handleSubmit(handleSubmitLogin)}
          className={styles['form-container']}
        >
          <img src={Logo} alt='nikaah-logo' className={styles['logo']} />

          <Form.Item
            label='email'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            labelAlign='left'
          >
            <Controller
              name='email'
              defaultValue=''
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input onChange={onChange} value={value} />
              )}
            />
          </Form.Item>

          <Form.Item
            label='Password'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            labelAlign='left'
          >
            <Controller
              name='password'
              defaultValue=''
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input.Password onChange={onChange} value={value} />
              )}
            />
          </Form.Item>

          <Button
            className='mb-8'
            disabled={isLoading}
            htmlType='submit'
            type='primary'
          >
            Login
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default LoginPage;
