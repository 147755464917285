import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BaseLayout } from 'components';
import { LoginPage } from 'features/login';
import { OrderPage } from 'features/order';
import { BankPage } from 'features/master-data';

import 'antd/dist/antd.min.css';
import './App.css';
import PrivateRoute from 'components/PrivateRoute';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route element={<PrivateRoute />}>
        <Route element={<BaseLayout />}>
          <Route path='/' element={<Navigate to='/orders' replace />} />
          <Route path='/orders' element={<OrderPage />} />
          <Route path='/master-data/bank' element={<BankPage />} />
          <Route path='/master-data/music' element={<div>music page</div>} />
          <Route path='*' element={<div>404</div>} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
