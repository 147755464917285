import { Button, Modal, message } from 'antd';
import React from 'react';
import { useRemoveBank } from '../networks';

interface OrderPaymentModalProps {
  visible: boolean;
  onClose: () => void;
  id?: number | undefined;
  onRefetch: () => void;
}

const DeleteBankModal: React.FC<OrderPaymentModalProps> = ({
  visible,
  onClose,
  id = '',
  onRefetch,
}) => {
  const {
    mutateAsync: removeBank,
    isLoading: removeBankLoading,
  } = useRemoveBank();

  const handleSubmit = async () => {
    try {
      await removeBank(`${id}`);
      message.success('berhasil hapus bank');

      onRefetch();
      onClose();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.msg || 'terjadi kesalahan, silahkan coba lagi';
      message.error(errorMessage);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title='Hapus Bank'
      footer={[
        <Button key='back' onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={removeBankLoading}
          disabled={removeBankLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      pastikan bank sudah tidak digunakan lagi!
    </Modal>
  );
};

export default DeleteBankModal;
