import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  WalletOutlined,
  CustomerServiceOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/common/logo.svg';

const { Header, Content, Sider } = Layout;

const BaseLayout: React.FC = () => {
  const [collapse, setCollapse] = useState(false);

  const toggleCollapse = () => setCollapse(!collapse);

  const navigate = useNavigate();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        theme='light'
        trigger={null}
        collapsible
        collapsed={collapse}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <img src={Logo} className='logo' alt='logo' />

        <Menu
          theme='light'
          mode='inline'
          defaultSelectedKeys={['order']}
          defaultOpenKeys={['master-data']}
          items={[
            {
              key: 'order',
              icon: <UserOutlined />,
              label: 'Order',
              onClick: () => navigate('/orders'),
            },
            {
              key: 'master-data',
              icon: <SettingOutlined />,
              label: 'Master Data',
              children: [
                {
                  key: 'music',
                  icon: <CustomerServiceOutlined />,
                  label: 'Music',
                  onClick: () => navigate('/master-data/music'),
                },
                {
                  key: 'bank',
                  icon: <WalletOutlined />,
                  label: 'Bank',
                  onClick: () => navigate('/master-data/bank'),
                },
              ],
            },
          ]}
        />
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: collapse ? 80 : 200,
          transition: 'all 0.25s ease 0s',
        }}
      >
        <Header
          className='site-layout-background'
          style={{
            padding: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 100,
          }}
        >
          {React.createElement(
            collapse ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: toggleCollapse,
            }
          )}
        </Header>
        <Content
          className='site-layout-background'
          style={{
            margin: '86px 24px 16px',
            padding: 24,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
