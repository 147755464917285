import { useMutation, useQuery } from 'react-query';
import {
  getLoginToken,
  getOrderList,
  getPaymentList,
  postAddManualPayement,
  postRegisterUser,
  postResetPassword,
  postUpdatePaymentStatus,
  postUpgradePackage,
} from './services';

/**
 * Fetching order list
 */
export const useFetchOrderList = (
  search = '',
  page = 1,
  pageSize = 10,
  enable = true
) => {
  return useQuery(
    ['Order List', { search, page, pageSize }],
    () => getOrderList(search, page, pageSize),
    {
      enabled: enable,
    }
  );
};

/**
 * Fetching order list
 */
export const useFetchPaymentList = (orderId: string, enable = true) => {
  return useQuery(['Payement List', orderId], () => getPaymentList(orderId), {
    enabled: enable,
  });
};

export const useUpgradePackage = () => useMutation(postUpgradePackage);

export const useUpdatePaymentStatus = () =>
  useMutation(postUpdatePaymentStatus);

export const useAddManualPayment = () => useMutation(postAddManualPayement);

export const useGetLoginToken = () => useMutation(getLoginToken);

export const useResetPassword = () => useMutation(postResetPassword);

export const useRegisterUser = () => useMutation(postRegisterUser);
