import axios, { AxiosRequestConfig } from 'axios';

const { REACT_APP_BASE_API_URL, REACT_APP_API_TIMEOUT } = process.env;

if (!REACT_APP_BASE_API_URL || !REACT_APP_API_TIMEOUT) {
  throw new Error('make sure ENV has been set');
}

/**
 * Create initiate API-Interface from AXIOS
 */
const api = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  timeout: Number(REACT_APP_API_TIMEOUT),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use((config: AxiosRequestConfig) => {
  const user = JSON.parse(localStorage.getItem('nikaah_admin') || 'null');

  if (!config?.headers) {
    throw new Error(
      `Expected 'config' and 'config.headers' not to be undefined`
    );
  }

  if (user?.access_token) {
    // eslint-disable-next-line no-param-reassign
    config.headers['Authorization'] = user.access_token;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.replace(`/login?redirect=${window.location.pathname}`);
    }
    throw error;
  }
);

export default api;
