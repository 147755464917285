import { Button, Select, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useFetchOrderList, useUpgradePackage } from '../networks';
import { Order } from '../types';

const { Option } = Select;

interface OrderPackageModalProps {
  visible: boolean;
  toggleVisible: () => void;
  data?: Order;
}

const OrderPackageModal: React.FC<OrderPackageModalProps> = ({
  visible,
  toggleVisible,
  data = null,
}) => {
  const [packageValue, setPackage] = useState('1');

  const { refetch } = useFetchOrderList();

  const {
    mutateAsync: upgradePackage,
    isLoading: upgradePackageLoading,
  } = useUpgradePackage();

  const handleChange = (value: string) => {
    setPackage(value);
  };

  useEffect(() => {
    if (visible) {
      setPackage(`${data?.package}`);
    }
  }, [visible, data]);

  const handleSubmit = async () => {
    try {
      const body = {
        oderId: `${data?.id}`,
        package: Number(packageValue),
      };

      await upgradePackage(body);
      refetch();
      toggleVisible();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.msg || 'terjadi kesalahan, silahkan coba lagi';
      message.error(errorMessage);
    }
  };

  return (
    <Modal
      onCancel={toggleVisible}
      visible={visible}
      title='Order Package'
      footer={[
        <Button key='back' onClick={toggleVisible}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={upgradePackageLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Select
        style={{ width: 200 }}
        onChange={handleChange}
        value={packageValue}
      >
        <Option value='1'>Silver</Option>
        <Option value='2'>Gold</Option>
        <Option value='3'>Platinum</Option>
      </Select>
    </Modal>
  );
};

export default OrderPackageModal;
