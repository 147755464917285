/* eslint-disable no-console */
import { Button, Modal, message, Input, Form, Select } from 'antd';
import React from 'react';
import { useRegisterUser } from '../networks';

const themeOptions = [
  {
    label: 'Java',
    value: 8,
  },
  {
    label: 'Minang',
    value: 9,
  },
  {
    label: 'Betawi',
    value: 10,
  },
  {
    label: 'Elegant',
    value: 1,
  },
  {
    label: 'Azalea',
    value: 6,
  },
  {
    label: 'Fuji',
    value: 7,
  },
  {
    label: 'Frozen',
    value: 11,
  },
  {
    label: 'Nature',
    value: 12,
  },
  {
    label: 'Sakura',
    value: 13,
  },
  {
    label: 'Modern',
    value: 4,
  },
  {
    label: 'Classic',
    value: 5,
  },
  {
    label: 'Minimalist',
    value: 2,
  },
  {
    label: 'Rose',
    value: 3,
  },
];

const { Option } = Select;

interface RegisterUserModalProps {
  visible: boolean;
  toggleVisible: () => void;
  onRefetch: () => void;
}

const RegisterUserModal: React.FC<RegisterUserModalProps> = ({
  visible,
  toggleVisible,
  onRefetch,
}) => {
  const [form] = Form.useForm();

  const { mutateAsync, isLoading } = useRegisterUser();

  const handleSubmitRegister = async () => {
    form
      .validateFields()
      .then(async (values) => {
        const {
          fullname,
          email,
          password,
          packageId,
          subDomain,
          themeId,
        } = values;
        try {
          const body = {
            fullname,
            email,
            password,
            package_id: packageId,
            sub_domain: subDomain,
            theme_id: themeId,
          };
          await mutateAsync(body as any);
          message.success('berhasil reset passsword');
          onRefetch();
          toggleVisible();
        } catch (error) {
          const errorMessage =
            error?.response?.data?.msg ||
            'terjadi kesalahan, silahkan coba lagi';
          message.error(errorMessage);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Modal
      onCancel={toggleVisible}
      visible={visible}
      title='Register New User'
      footer={[
        <Button key='back' onClick={toggleVisible}>
          Cancel
        </Button>,
        <Button
          type='primary'
          loading={isLoading}
          onClick={handleSubmitRegister}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        name='basic'
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        onFinish={(value) => console.log(value)}
        onFinishFailed={(error) => console.log({ failded: error })}
        autoComplete='off'
      >
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your email',
            },
            {
              type: 'email',
              message: 'Please input valid email',
            },
          ]}
        >
          <Input placeholder='Input email' />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder='Input password' />
        </Form.Item>

        <Form.Item
          label='Fullname'
          name='fullname'
          rules={[{ required: true, message: 'Please input your fullname!' }]}
        >
          <Input placeholder='Input fullname' />
        </Form.Item>

        <Form.Item label='Sub Domain' name='subDomain'>
          <Input
            placeholder='Input sub domain'
            addonAfter={<div>Nikaah.id</div>}
          />
        </Form.Item>

        <Form.Item name='packageId' label='Package'>
          <Select placeholder='Select package' allowClear>
            <Option value={1}>Silver</Option>
            <Option value={2}>Gold</Option>
            <Option value={3}>Platinum</Option>
          </Select>
        </Form.Item>

        <Form.Item name='themeId' label='Theme'>
          <Select placeholder='Select theme' allowClear>
            {themeOptions.map((item) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegisterUserModal;
