import React, { useEffect, useState } from 'react';
import { Button, Space, Table } from 'antd';

import styles from './BankPage.module.css';
import { Bank } from '../types';
import { useFetchBankList } from '../networks';
import { AddBankModal, DeleteBankModal } from '../modals';

const OrderTable: React.FC = () => {
  const [selectedBank, setSeledtedBank] = useState<Bank>();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [selectedBankId, setSelectedBankID] = useState<number | undefined>(
    undefined
  );

  const handleClickAddBank = () => {
    setAddModalVisible(true);
  };

  const handleClickCloseBank = () => {
    setAddModalVisible(false);
  };

  const handleClickUpdateBank = (data: Bank) => () => {
    setSeledtedBank(data);
    setAddModalVisible(true);
  };

  const handleClickDeleteBank = (data: Bank) => () => {
    setSelectedBankID(data.id);
  };

  useEffect(() => {
    if (!addModalVisible) {
      setSeledtedBank(undefined);
    }
  }, [addModalVisible]);

  const handleCloseDeleteBankModal = () => {
    setSelectedBankID(undefined);
  };

  const { data, isFetching: orderListLoading, refetch } = useFetchBankList();

  const bankListData = data?.data?.data;
  const bankList = (bankListData || []).map((item, index) => ({
    no: index + 1,
    ...item,
  }));

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (text: string) => <div>{text ?? '-'}</div>,
      width: 60,
    },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'fullname',
      render: (text: string) => <div>{text || '-'}</div>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'fullname',
      render: (text: string) => <div>{text || '-'}</div>,
      width: 300,
    },
    {
      title: 'Image',
      dataIndex: 'icon_url',
      key: 'fullname',
      render: (text: string, record: Bank) => (
        <div>
          {' '}
          <img className={styles['bank-image']} src={text} alt={record.name} />
        </div>
      ),
      width: 300,
    },

    {
      title: 'Action',
      key: 'action',
      render: (record: Bank) => (
        <Space size='middle'>
          <Button onClick={handleClickUpdateBank(record)}>Edit</Button>
          <Button onClick={handleClickDeleteBank(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ overflow: 'auto' }}>
      <div className={styles['order-header']}>
        <h1>Order</h1>
        <div className={styles['right']}>
          <Button onClick={handleClickAddBank}>Add Bank</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={bankList}
        loading={orderListLoading}
        rowKey={(record) => record.id}
        pagination={false}
      />

      <DeleteBankModal
        onRefetch={refetch}
        id={selectedBankId}
        visible={!!selectedBankId}
        onClose={handleCloseDeleteBankModal}
      />

      <AddBankModal
        onRefetch={refetch}
        data={selectedBank}
        visible={addModalVisible}
        onClose={handleClickCloseBank}
      />
    </div>
  );
};

export default OrderTable;
