import { Button, Modal, Image, Col, Row, Skeleton } from 'antd';
import React from 'react';
import { useFetchPaymentList } from '../networks';
import { Order } from '../types';

interface OrderPaymentModalProps {
  visible: boolean;
  toggleVisible: () => void;
  data?: Order;
}

const SkeletonList = () => {
  return (
    <div style={{ padding: '18px 10px' }}>
      <Row>
        <Col span={6}>
          <Skeleton.Image />
        </Col>
        <Col span={18}>
          <Row>
            <Col span={24}>
              <Skeleton active paragraph={{ rows: 1 }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const OrderPaymentListModal: React.FC<OrderPaymentModalProps> = ({
  visible,
  toggleVisible,
  data = null,
}) => {
  const { data: paymentListData, isLoading } = useFetchPaymentList(
    `${data?.id}`,
    visible
  );

  const paymentList = paymentListData?.data?.data || [];

  const totalPayment = paymentList
    .map((item) => item.amount)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <Modal
      onCancel={toggleVisible}
      visible={visible}
      title='Order Payment'
      footer={[
        <Button key='back' onClick={toggleVisible}>
          Close
        </Button>,
      ]}
    >
      <div>
        <div
          style={{
            backgroundColor: '#f6ffed',
            border: '1px solid #b7eb8f',
            padding: 10,
          }}
        >
          <h3 style={{ margin: 0 }}>
            Total:{' '}
            {new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0,
            }).format(totalPayment)}
          </h3>
        </div>

        {isLoading && <SkeletonList />}

        {!isLoading &&
          paymentList.map((item, index) => (
            <div
              style={{
                borderTop: index !== 0 ? '1px solid #ededed' : 'none',
                padding: '18px 0',
              }}
            >
              <Row>
                <Col span={6}>
                  <Image
                    src={item?.proof_url}
                    height={100}
                    width={100}
                    style={{ objectFit: 'contain' }}
                  />
                </Col>
                <Col span={18}>
                  <Row>
                    <Col span={6}>Code</Col>
                    <Col>: {item?.code}</Col>
                  </Row>
                  <Row>
                    <Col span={6}>Date</Col>
                    <Col>: {item.updated_at}</Col>
                  </Row>
                  <Row>
                    <Col span={6}>Method</Col>
                    <Col>: {item.type}</Col>
                  </Row>
                  <Row>
                    <Col span={6}>Amount</Col>
                    <Col>
                      :{' '}
                      {new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0,
                      }).format(item.amount)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}

        {!isLoading && paymentList.length === 0 && (
          <div style={{ padding: '20px 10px' }}>belum ada pembayaran</div>
        )}
      </div>
    </Modal>
  );
};

export default OrderPaymentListModal;
